
import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';



export function HomePage() {
    const navigate = useNavigate(); // Use the useNavigate hook

    return (
        <div>
            <h1>Welcome to Cryptograms!</h1>
            <p>This is the home page.</p>
            <Button onClick={() => navigate('/load-games')}>Load Games</Button>
        </div>
    );
}

export default HomePage;