// src/types/gameTypes.ts

import { UserSolution } from "../utils/cipher";


/**
 * GameParams are the parameters for a game that comes from the server
 *
 * @export
 * @interface GameParams
 */
export interface GameParams {
    cipherText: string;
    solutionHash: string;
    hintMapping?: string;
}

/**
 * Status is the status of a game
 *
 * @export
 * @enum {number}
 */
export enum Status {
    New = 'new',
    InProgress = 'inProgress',
    Completed = 'completed',
}

export const statusProps: { [key in Status]: { name: string; icon: string } } = {
    [Status.New]: { name: 'New', icon: '🔵' },
    [Status.InProgress]: { name: 'In Progress', icon: '🟡' },
    [Status.Completed]: { name: 'Completed', icon: '🟢' },
};

/**
 * StoredGame is the type for a game that is stored in localStorage
 * 
 * @export
 * @interface StoredGame
 */
export interface StoredGame {
    id: string;
    status: Status;
    gameParams: GameParams;
    plainTextSolution?: string | null;
    timeElapsed?: number;
    startDateTime: number;
    completedDateTime: number;
    userEntries: UserSolution | null;
}

export interface LetterRef {
    current: HTMLInputElement | null;
}

export interface LetterCounts {
    [key: string]: number;
}
