declare global {
    // eslint-disable-next-line no-var
    var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
  }

import config from "./firebaseConfig"; // Firebase project configuration object
import { initializeApp, registerVersion, } from "firebase/app"; // Firebase app
// import { getAuth, connectAuthEmulator } from "firebase/auth"; // Firebase auth
// import { getFirestore , connectFirestoreEmulator } from "firebase/firestore"; // Firestore
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";  // Firebase functions

// import { getDatabase, connectDatabaseEmulator } from "firebase/database"; // Firebase database

// Add additional firebase services for analytics and messaging
import { getAnalytics } from "firebase/analytics"; // Firsebase analytics
// import { getMessaging } from "firebase/messaging"; // Firebase messaging
import { getPerformance, trace } from "firebase/performance"; // Firebase performance
// import { getRemoteConfig } from "firebase/remote-config"; // Firebase remote config
// import { getStorage } from "firebase/storage"; // Firebase storage
// import { initializeAppCheck, ReCaptchaV3Provider, ReCaptchaEnterpriseProvider } from "firebase/app-check"; // Firebase app check

registerVersion('firebase', '9.17.2', 'app');
registerVersion('@mantine_core', '7.10.2', 'node');
registerVersion('@mantine_form', '7.10.2', 'node');
registerVersion('@mantine_hooks', '7.11.2', 'node');
registerVersion('@mantine_notifications', '7.12.0', 'node');
registerVersion('@tabler_icons-react', '3.7.0', 'node');
registerVersion('firebase', '10.12.3', 'node');
registerVersion('react', '18.2.0', 'node');
registerVersion('react-dom', '18.2.0', 'node');
registerVersion('react-router-dom', '6.24.0', 'node');
registerVersion('uuid', '10.0.0', 'node');
registerVersion('workbox-precaching', '7.1.0', 'node');

// Initialize firebase
const firebaseApp = initializeApp(config);
// Initialize analytics
const analytics = getAnalytics(firebaseApp);

// Initialize auth, firestore, and functions with the 'firebaseApp' property
// const auth = getAuth(firebaseApp);
// const firestore = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);
// const database = getDatabase(firebaseApp);

// Initialize Firebase Cloud Messaging and get a reference to the service
// const messaging = getMessaging(firebaseApp);
// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(firebaseApp);
perf.dataCollectionEnabled = true;
perf.instrumentationEnabled = true;

// Check if environment is development with variable VITE_USE_EMULATOR
if ((import.meta.env.DEV === true) && (import.meta.env.VITE_USE_EMULATOR==='true')) {

    // Connect to the emulator
    console.log("Using development environment. Connecting to the emulator");
    // connectAuthEmulator(auth, "http://127.0.0.1:9099");
    // connectFirestoreEmulator(firestore, "127.0.0.1",8080);
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    // connectDatabaseEmulator(database, "127.0.0.1", 9000);
    // self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    // self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.APP_CHECK_DEBUG_TOKEN_FROM_CI;

} else if ( import.meta.env.VITE_USE_EMULATOR === 'false' ) {
    // Initialize analytics
    // const analytics = getAnalytics(firebaseApp);
    console.log("This is firebaseInit.ts : Using Production ",import.meta.env.VITE_USE_EMULATOR);
    
}

// const appCheck = initializeAppCheck(firebaseApp, {
//     // provider: new ReCaptchaV3Provider('6Lcuou0mAAAAAEThv58FtB8c7A8kETduWu8iuF_k'),
//     provider: new ReCaptchaEnterpriseProvider('6Lcuou0mAAAAAEThv58FtB8c7A8kETduWu8iuF_k'),
//     // Optional argument. If true, the SDK automatically refreshes App Check
//     // tokens as needed.
//     isTokenAutoRefreshEnabled: true
//   });

// Export the auth and firestore properties
// export {firebaseApp, auth, firestore, functions, database, messaging, perf, trace, analytics };
export {firebaseApp, functions, analytics, perf, trace};

export default firebaseApp;
