// Code for the main App component
import { useState, useEffect } from 'react';
import { GameParams, statusProps, Status, StoredGame } from '../types/gameTypes';
import { Button, Table, Accordion, Text, Group, Chip, Title, Divider, Badge } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getGames } from '../firebase/functions';
import { perf, trace } from '../firebase/firebaseInit';
import { notifications } from '@mantine/notifications';

// async function pseudoFetch(url: string): Promise<{ json: () => Promise<GameParams[]> }> {
//   // Simulate a fetch request that takes 1 second to complete
//   console.log('This is a psuedo fetch call. Pretending to fetch data from:', url);
//   // Data returned from the API
//   const data: GameParams[] =
//   [
//     {
//       solutionHash: "f48d36c2700ecdf665375b6101099177a1f84c11a29a87ad62427a662b28f1ba",
//       cipherText: "CG UCT ROSR CG BOE OEW CG UCT ROSR CG BOE'H ODG LTHC NRNOAAS DYICH.",
//     },
//     {
//       solutionHash: "9cbc2f9fffd0cde3de21cbc9b62576b008a8e4d4cb868ffd493ddb4205ee1efd",
//       cipherText: "RO HRC UWTU RO AWS WSB RO HRC UWTU RO AWS'E WGO ICER NUNWYYT GDLRE.",
//     },
//     {
//       solutionHash: "465405f3bbfbea190ea9b7674af7def470dd324334bf1f6eb46c3d203b1e91be",
//       cipherText: "AD LAY RUNR AD BUG UGS AD LAY RUNR AD BUG'I UWD HYIA OROUCCN WTEAI.",
//     },
//   ];

//   // Return a promise that resolves after 1 second
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         json: () => Promise.resolve(data)
//       });
//     }, 1000);
//   });
// }

function toStatus(value: string): Status {
  if (Object.values(Status).includes(value as Status)) {
    return value as Status;
  }
  throw new Error(`Invalid status: ${value}`);
}

export function LoadGamesPage() {
  const navigate = useNavigate(); // Step 2: Get the navigate function from useNavigate
  const [loading, setLoading] = useState<boolean>(false);
  const [storedGames, setStoredGames] = useState<StoredGame[]>(() => {
    // Retrieve stored games from localStorage if available
    const savedGames = localStorage.getItem('storedGames');
    return savedGames ? JSON.parse(savedGames) : [];
  });

  useEffect(() => {
    // Save stored games to localStorage whenever it changes
    localStorage.setItem('storedGames', JSON.stringify(storedGames));
  }, [storedGames]);

  const [currentAccordionValue, setCurrentAccordionValue] = useState<string>(() => {
    // Attempt to get a saved value from local storage
    const saved = localStorage.getItem('currentAccordionValue');
    return saved !== null ? saved : '';
  });

  useEffect(() => {
    // Save the current value to local storage whenever it changes
    localStorage.setItem('currentAccordionValue', currentAccordionValue);
  }, [currentAccordionValue]);

  function deleteGameFromStoredGames(gameId: string) {
    console.debug('Deleting game from stored games:', gameId);
    const updatedGames = storedGames.filter((storedGame) => storedGame.id !== gameId);
    setStoredGames(updatedGames);
  }

  function debugSetGameStatus(gameId: string, status: Status) {
    console.debug('Setting game status:', gameId, status);
    const updatedGames = storedGames.map((storedGame) => {
      if (storedGame.id === gameId) {
        return {
          ...storedGame,
          status: status
        };
      }
      return storedGame;
    });
    setStoredGames(updatedGames);
  }

  async function getNewGamesBatch() {
    // Call the API to get new games. It will return an array of new games which we need to store in storedGames along with the status 'new'
    setLoading(true);

    console.debug('Getting new games batch');
    const t = trace(perf, "getNewGamesBatch");
    t.start();
    try {
      const response = await getGames();
      if (response instanceof Error) {
        throw response;
      }
      console.debug(response);
      // Assuming response is the array of new games directly
      const newGames: StoredGame[] = (response as GameParams[]).map((gameParams: GameParams) => ({
        id: uuidv4(),
        status: Status.New,
        gameParams: gameParams,
        timeElapsed: 0,
        startDateTime: 0,
        completedDateTime: 0,
        plainTextSolution: null,
        userEntries: null
      }));
      setStoredGames(prevGames => [...prevGames, ...newGames]);
    } catch (error) {
      console.error("Failed to fetch new games:", error);
      notifications.show({ message: `Failed to fetch new games: ${error}`, color: "red" });
    }
    setLoading(false);
    t.stop();
  }

  // Handler function defined inside the component to access navigate
  function startGameButtonHandler(gameId: string) {
    navigate('/gamepage', { state: gameId });
  }

  /**
   * Format the time in miliseconds to a string in the format 'mm:ss'
   *
   * @param {number} time
   * @return {*}  {string}
   */
  function formatTime(time: number): string {
    const minutes = Math.floor(time / 60 / 1000);
    const seconds = Math.floor((time / 1000) % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }


  const accordianItems = storedGames.map((game, index) => (
    <Accordion.Item key={index} value={String(index + 1)}>
      <Accordion.Control icon={statusProps[game.status].icon}>{`Game ${index + 1} - ${statusProps[game.status].name}`}
        {game.plainTextSolution && ` - ${game.plainTextSolution}`}
      </Accordion.Control>
      <Accordion.Panel>
        <Table highlightOnHover>
          <Table.Tbody>
            {game.plainTextSolution &&
              <Table.Tr >
                <Table.Td miw={70}><Text c="green" fw={700}>Solved Phrase</Text></Table.Td>
                <Table.Td><Text c="green" fw={700}>{game.plainTextSolution}</Text></Table.Td>
              </Table.Tr>}
            {typeof game.timeElapsed !== 'undefined' && game.timeElapsed !== null &&
              <Table.Tr>
                <Table.Td miw={70}>Time Elapsed</Table.Td>
                <Table.Td>{formatTime(game.timeElapsed)}</Table.Td>
              </Table.Tr>}
            {typeof game.startDateTime !== 'undefined' && game.startDateTime !== null && game.startDateTime > 0 &&
              <Table.Tr>
                <Table.Td miw={70}>Game Started</Table.Td>
                <Table.Td>{new Date(game.startDateTime).toLocaleString()}</Table.Td>
              </Table.Tr>}
            <Table.Tr >
              <Table.Td miw={70}>Cipher Text</Table.Td>
              <Table.Td>{game.gameParams.cipherText}</Table.Td>
            </Table.Tr>
            <Table.Tr >
              <Table.Td miw={70}>Tags</Table.Td>
              <Table.Td><Badge autoContrast size='md' color='myColors'>Tags</Badge><Badge autoContrast size='md' color='myRedPalette'>Coming Soon</Badge></Table.Td>
            </Table.Tr>
            {/* <Table.Tr >
              <Table.Td>Solution Hash</Table.Td>
              <Table.Td>{game.gameParams.solutionHash}</Table.Td>
            </Table.Tr> */}
          </Table.Tbody>
        </Table>

        {/* Add a group of buttons */}
        <Group justify="center" gap="lg">
          <Chip.Group
            multiple={false}
            value={game.status}
            onChange={(newStatus) => {
              // Convert the string to Status before passing it to debugSetGameStatus
              const status = toStatus(newStatus);
              debugSetGameStatus(game.id, status);
            }}
          >
            <Group justify="center" gap="xs">
              <Chip color="blue" value={Status.New}>Not Started</Chip>
              <Chip color="yellow" value={Status.InProgress}>In Progress</Chip>
              <Chip color="green" value={Status.Completed}>Completed</Chip>
            </Group>
          </Chip.Group>
          <Button onClick={() => startGameButtonHandler(game.id)}>Start Game</Button>
          <Button color="red" onClick={() => deleteGameFromStoredGames(game.id)}>Delete Game</Button>
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <div>
      <Title order={2}>Games List</Title>
      <Text>
        Below are a list of the games on this device, along with their status. Completed games have additional stats. You can use the button below to get new games from the server. These will be saved locally on your device and will be playable while offline.
      </Text>
      <Button onClick={getNewGamesBatch} loading={loading}>Get New Games</Button>
      <Divider my="lg" />
      <Accordion
        variant="filled"
        value={currentAccordionValue}
        defaultValue={currentAccordionValue}
        onChange={(newValue) => {
          if (newValue !== null) {
            setCurrentAccordionValue(newValue);
          } else {
            setCurrentAccordionValue('');
          }
        }}
      >
        {accordianItems}
      </Accordion>
    </div>
  );
}

export default LoadGamesPage;