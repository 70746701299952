// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBbRXfHgeP25VOzJ8cvwzJBXl0z8InBNnM",
  authDomain: "terminalwebapp.firebaseapp.com",
  projectId: "terminalwebapp",
  storageBucket: "terminalwebapp.appspot.com",
  messagingSenderId: "634416543438",
  appId: "1:634416543438:web:9dd0b8d94e1cec0115829f",
  measurementId: "G-500P6EHKRS"
};
  

export default firebaseConfig;