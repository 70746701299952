import { useEffect, useRef, useState } from 'react';
// Code for the main App component
import { useLocation } from 'react-router-dom';
import { StoredGame, Status } from "../types/gameTypes";
import { Game } from "../components/Game";
import { Text, Title } from '@mantine/core';
import { Stopwatch } from '../components/Stopwatch';
import TotalElapsedCounter from '../components/TotalElapsedCounter';
import { UserSolution } from '../utils/cipher';

export function GamePage() {
  const location = useLocation();
  const gameId = location.state ? (location.state as { gameId: string }) : '';
  const [elapsedTime, setElapsedTime] = useState(0);
  const [startDateTime, setStartDateTime] = useState(0);
  const [completedDateTime, setCompletedDateTime] = useState(0);
  const [startStopwatch, setStartStopwatch] = useState(false);
  const [currentEntries, setCurrentEntries] = useState<UserSolution | undefined>(undefined);


  const [storedGames, setStoredGames] = useState<StoredGame[]>(() => {
    // Retrieve stored games from localStorage if available
    const savedGames = localStorage.getItem('storedGames');
    return savedGames ? JSON.parse(savedGames) : [];
  });

  const [currentGame, setCurrentGame] = useState<StoredGame | null>(null);

  // Assuming elapsedTime can be a ref if it doesn't need to cause re-renders
  const elapsedTimeRef = useRef(elapsedTime);

  useEffect(() => {
    // Save stored games to localStorage whenever it changes
    localStorage.setItem('storedGames', JSON.stringify(storedGames));
    const tempCurrentGame = storedGames.find((game) => game.id === gameId);
    if (tempCurrentGame) {
      setCurrentGame(tempCurrentGame);
      setElapsedTime(tempCurrentGame.timeElapsed || 0);
      setStartDateTime(tempCurrentGame.startDateTime || 0);
      setCompletedDateTime(tempCurrentGame.completedDateTime || 0);
      elapsedTimeRef.current = tempCurrentGame.timeElapsed || 0; // Update ref
      setCurrentEntries(tempCurrentGame.userEntries || undefined);
    }
  }, [storedGames, gameId]);


  useEffect(() => {
    if (currentGame && elapsedTimeRef.current !== elapsedTime) {
      // Only update if elapsedTime has actually changed
      const updatedGames = storedGames.map(game => {
        if (game.id === currentGame.id) {
          return { ...game, timeElapsed: elapsedTime };
        }
        return game;
      });

      setStoredGames(updatedGames);
    }
  }, [elapsedTime, currentGame]); // Removed storedGames and setStoredGames from dependencies to avoid loop

  // const currentGame = storedGames.find((game) => game.id === gameId);

  if (!currentGame) {
    return (
      <div>
        <Title order={2}>Game</Title>
        <Text>Game not found.</Text>
      </div>
    );
  }

  const gameParams = currentGame.gameParams;


  function setGameStatus(targetStatus: Status, plainTextSolution?: string) {
    console.log("Plain Text Solution: ", plainTextSolution);
    // Find the index of the game by ID
    const gameIndex = storedGames.findIndex((game) => game.id === gameId);

    // Temporary variables for date times
    let tempStartDateTime = startDateTime;
    let tempCompletedDateTime = completedDateTime;

    // If the game is found, update its status
    if (gameIndex !== -1 && storedGames[gameIndex].status !== targetStatus) {

      // If the game is going from not started to in progress, set the startDateTime
      if (targetStatus === Status.New) {
        tempStartDateTime = 0; // Reset the start time
        console.debug('Resetting start datetime');
      } else if (targetStatus === Status.InProgress && startDateTime === 0) {
        tempStartDateTime = Date.now(); // Record the start time
        console.debug('Setting start datetime to:', tempStartDateTime);
      } else if (targetStatus === Status.Completed) {
        tempCompletedDateTime = Date.now(); // Record the completion time
        console.debug('Setting completed datetime to:', tempCompletedDateTime);
      }


      const updatedGames = [
        ...storedGames.slice(0, gameIndex),
        { ...storedGames[gameIndex], status: targetStatus, plainTextSolution: plainTextSolution, startDateTime: tempStartDateTime, completedDateTime: tempCompletedDateTime },
        ...storedGames.slice(gameIndex + 1),
      ];

      console.log('Setting game status:', targetStatus);
      // Update the storedGames state with the new array
      setStoredGames(updatedGames);

      // Update the state with the new datetime values
      setStartDateTime(tempStartDateTime);
      setCompletedDateTime(tempCompletedDateTime);
    }
  }

  function handleFirstInteraction() {
    setGameStatus(Status.InProgress);
    setStartStopwatch(true);
  }

  function handleDebugButton() {
    setStartStopwatch(false);
  }

  function handleOnEmitUpdatedCount(count: number) {
    setElapsedTime(count);
  }

  function handleOnSolve(plainTextSolution: string) {
    setGameStatus(Status.Completed, plainTextSolution);
    console.log('TODO: Store plaintext of solved game:', plainTextSolution);
    setStartStopwatch(false);
  }

  function handleUserSolutionUpdate(userEntries: UserSolution) {
  
    if (currentGame) {
      const gameIndex = storedGames.findIndex((game) => game.id === gameId);
  
      if (gameIndex !== -1) {
        const updatedGames = [
          ...storedGames.slice(0, gameIndex),
          { ...storedGames[gameIndex], userEntries: userEntries },
          ...storedGames.slice(gameIndex + 1),
        ];
  
        setStoredGames(updatedGames);
        localStorage.setItem('storedGames', JSON.stringify(updatedGames));
      }
    }
  }

  return (
    <div>
      {/* <Title order={2}>Game</Title> */}
      {/* <Text>This is the Games page.</Text> */}
      {gameParams && <>
        <Stopwatch initialCount={elapsedTime} startImmediately={startStopwatch} onEmitUpdatedCount={handleOnEmitUpdatedCount} />
        <TotalElapsedCounter startDateTime={startDateTime} endDateTime={completedDateTime} />
        <Game gameParams={gameParams} onFirstInteraction={handleFirstInteraction} onDebugButton={handleDebugButton} onSolve={handleOnSolve} onUserSolutionUpdate={handleUserSolutionUpdate} plainTextSolution={currentGame.plainTextSolution} initialUserSolution={currentEntries} />
      </>}
    </div>
  );
}

export default GamePage;