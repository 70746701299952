import { httpsCallable, httpsCallableFromURL, FunctionsError, FunctionsErrorCode } from "firebase/functions";
import { functions } from "./firebaseInit";

type RequestData = unknown;

function isFirebaseFunctionsError(error: unknown): error is FunctionsError {
    return error instanceof Error && 'code' in error;
  }

/**
 * Calls a Firebase function with the given name and data.
 *
 * @export
 * @param {string} name
 * @param {RequestData} [messagedata]
 * @return {*} 
 */
export async function callFunction(name: string, messagedata?:  RequestData) {
        const addMessage = httpsCallable(functions, name);
      
        try {
            const result = await addMessage(
              messagedata ? { data: messagedata } : undefined);
            // Read result of the Cloud Function.
            return result.data;
        } catch (error) {
            if (isFirebaseFunctionsError(error)) {
                // Now TypeScript knows error is a FunctionsError
                console.log(error.code); // Access FunctionsError specific property
                return new Error(error.message);
              } else {
                // Handle generic errors
                return new Error('An unknown error occurred');
              }
        }
    }

/**
 * Calls a Firebase function with the given URL and data.
 *
 * @export
 * @param {string} url
 * @param {RequestData} [messagedata]
 */
export function callFunctionFromURL(url: string, messagedata?: RequestData) {
    const addMessage = httpsCallableFromURL(functions, url);

    addMessage({ data : messagedata , cors: false}).then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
        // const sanitizedMessage = data.text;
        return data;
    }
    ).catch((error) => {
        if (isFirebaseFunctionsError(error)) {
            // Getting the Error details.
            const code: FunctionsErrorCode = error.code;
            const message = error.message;
            const details = error.details;
        
            console.log(code, message, details);

            return new Error(error.message);
          } else {
            // Handle generic errors
            return new Error('An unknown error occurred');
          }

    }
    );

}
 

/**
 * Calls the newGamesBatch function which returns an array of new games.
 *
 * @export
 * @return {*} 
 */
export async function getGames() {
    try {
        // const newGames = await callFunction('newGamesBatch', {"data": "data"});
        return await callFunction('newGamesBatch');
    } catch (error) {
        if (isFirebaseFunctionsError(error)) {
            // Now TypeScript knows error is a FunctionsError
            console.log(error.code); // Access FunctionsError specific property
            return new Error(error.message);
          } else {
            return new Error('An unknown error occurred'); // Handle generic errors
          }
    }
}

export {};